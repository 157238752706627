<template>
	<div class="account">
		<div class="a_title">账户信息</div>
		<div class="account_info">
			<div class="phone">
				<div class="img_box">
					<div class="img">
						<img v-if="headimg" :src="headimg" alt="" />
						<img v-else src="../../static/img/wxl_touxiang.jpg" alt="" />
						<div class="mengban" @click="changeinfo(1,'修改头像')">修改头像</div>
					</div>
				</div>
			</div>
			<div class="phone">
				<div class="name"></div>
				<div class="tit">{{nickname}}</div>
				<div class="change" @click="changeinfo(2,'修改昵称')">修改</div>
			</div>
			<div class="phone">
				<div class="name">手机号</div>
				<div class="num">{{phone}}</div>
				<div class="change" @click="changeinfo(3,'修改手机号')">修改</div>
			</div>
			<div class="phone">
				<div class="name">邮箱</div>
				<div class="num">{{email}}</div>
				<div class="change" @click="changeinfo(4,'修改邮箱')">修改</div>
			</div>
			<div class="phone">
				<div class="name">登录密码</div>
				<div class="num">**********</div>
				<div class="change" @click="changeinfo(5,'修改密码')">修改</div>
			</div>
		</div>
		<el-dialog class="dialog" :title="`${title}`" :visible.sync="dialogFormVisible">
			<!-- 修改头像 -->
			<div class="headimg" v-if="text == 1">
				<cus-upload :src="headimg" @change="change_img" />
			</div>

			<!-- 修改用户名 -->
			<div class="username" v-if="text == 2">
				<el-input class="name_inp" v-model="xname" placeholder="请输入新的用户名" />
				<div class="yanzhen">
					<el-input class="yz_inp" v-model="code" placeholder="请输入验证码" />
					<SIdentify :identifyCode="identifyCode" />
					<div @click="refreshCode" :class="rotate ? 'go' : ''" class="refresh iconfont icon-shuaxin"></div>
				</div>
			</div>
			<!-- 修改手机号 -->
			<div class="username" v-if="text == 3">
				<template v-if="phone">
					<div class="yanzhen">
						<el-input class="yz_inp" v-model="code" placeholder="请输入原手机号验证码" />
						<el-button>发送原手机号验证码</el-button>
					</div>
				</template>
				<el-input class="name_inp" v-model="xphone" placeholder="请输入新手机号" />
				<div class="yanzhen">
					<el-input class="yz_inp" v-model="xcode" placeholder="请输入验证码" />
					<el-button>发送验证码</el-button>
				</div>
			</div>
			<!-- 修改邮箱 -->
			<div class="username" v-if="text == 4">
				<template v-if="email">
					<div class="yanzhen">
						<el-input class="yz_inp" v-model="code" placeholder="请输入原邮箱验证码" />
						<el-button>发送原邮箱号验证码</el-button>
					</div>
				</template>
				<el-input class="name_inp" v-model="xemail" placeholder="请输入新邮箱" />
				<div class="yanzhen">
					<el-input class="yz_inp" v-model="xcode" placeholder="请输入验证码" />
					<el-button>发送验证码</el-button>
				</div>
			</div>
			<!-- 修改密码 -->

			<div class="username" v-if="text == 5">
				<el-input class="name_inp" v-model="xphone" placeholder="请输入手机号" />
				<div class="yanzhen">
					<el-input class="yz_inp" v-model="code" placeholder="请输入验证码" />
					<el-button>发送验证码</el-button>
				</div>
				<el-input class="name_inp" v-model="pwd" placeholder="请输入新密码" />
				<el-input class="name_inp" v-model="qrpwd" placeholder="再次输入密码" />
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="info_cel">取 消</el-button>
				<el-button type="primary" @click="info_sub">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import CusUpload from '../from/cus-upload.vue';
	import SIdentify from "../Sidentify/Sidentify.vue";
	import axios from "../../common/axios";
	export default {
		components: {
			SIdentify,
			CusUpload,
		},
		mounted() {
			this.identifyCode = "";
			this.makeCode(this.identifyCodes, 4);
			this.get_user();
		},
		data() {
			return {
				dialogFormVisible: false, //控制弹窗关闭/打开
				headimg: "",
				phone: "",
				xheadimg: "",
				email: "",
				nickname: "", //昵称
				code: "",
				xname: "",
				xcode: "",
				text: "",
				title: "", //弹窗标题
				xphone: "",
				pwd: "",
				qrpwd: "",
				xemail: "",
				rotate: false, //刷新验证码
				identifyCode: "",
				identifyCodes: "0123456789abcdwerwshdjeJKDHRJHKOOPLMKQ", //绘制的随机数
			};
		},
		methods: {
			change_img(e) {
				// console.log(e);
				this.xheadimg = e.value;
			},

			get_user() {
				// console.log(1);
				let url = "/adminshop/login/get_user.html"

				axios.post(url).then((res) => {
					let success = res.success;
					let data = res.data;
					if (success) {

						if (data.status == 1) {
							this.headimg = data.headimg;
							this.nickname = data.nickname;
							this.phone = data.phone;
							this.email = data.email;
						}
					} else {
						console.log(res.error);
					}
				});
			},
			info_sub() {
				// this.dialogFormVisible = false;//显示隐藏弹窗
				let sta = this.text;
				if (sta == 1) {
					this.edit_headimg();
				} else if (sta == 2) {
					this.edit_nickname();
				}
			},
			info_cel() {
				this.dialogFormVisible = false;
				setTimeout(() => {
					this.xphone = "";
					this.xemail = "";
					this.xheadimg = "";
					this.xname = "";
					this.xcode = "";
					this.code = "";
					this.text = "";
					this.title = "";
				}, 300)
			},
			edit_headimg() {
				let xheadimg = this.xheadimg;
				if (xheadimg == '') {
					this.$message.error('请上传需要修改的头像');
					return;
				}
				
				let url = "/adminshop/user/edit_headimg.html";
				let data = {img_src: xheadimg};
				axios.post(url,data).then((res) => {
					let success = res.success;
					let data = res.data;
					if (success) {
						if (data.status == 1) {
							// this.headimg = data.headimg;
							this.dialogFormVisible = false; //显示隐藏弹窗
							this.$message({
								message: data.msg,
								type: 'success'
							});
							setTimeout(() => {
								window.location.reload();
							}, 1200)
						} else {
							this.$message.error(data.msg);
						}
					} else {
						console.log(res.error);
					}
				});
				// console.log(xheadimg)
			},
			edit_nickname() {
				let nickname = this.xname;
				let code = this.code;
				if (nickname == '') {
					this.$message.error('请输入修改昵称');
					return;
				}
				if (code.toLowerCase() != this.identifyCode.toLowerCase()) {
					this.$message.error('验证码错误');
					return;
				}
				
				let url = "/adminshop/user/edit_nickname.html";
				let data = {nickname};

				axios.post(url,data).then((res) => {
					let success = res.success;
					let data = res.data;
					if (success) {
						if (data.status == 1) {
							// this.nickname = data.nickname;
							this.dialogFormVisible = false; //显示隐藏弹窗
							this.xname = "";
							this.code = "";
							this.$message({
								message: data.msg,
								type: 'success'
							});
							setTimeout(() => {
								window.location.reload();
							}, 1200)
						} else {
							this.$message.error(data.msg);
						}
					} else {
						console.log(res.error);
					}
				});
				// console.log(xheadimg)
			},
			changeinfo(sta, title) {
				this.dialogFormVisible = true; //显示隐藏弹窗
				this.text = sta;
				this.title = title;
				this.refreshCode(); //刷新验证码
			},
			// 验证码处理
			randomNum(min, max) {
				return Math.floor(Math.random() * (max - min) + min);
			},

			// 刷新验证码
			refreshCode() {
				this.identifyCode = "";
				this.makeCode(this.identifyCodes, 4);
				this.rotate = false;
				this.$nextTick(() => {
					this.rotate = true;
				});
			},
			makeCode(o, l) {
				for (let i = 0; i < l; i++) {
					this.identifyCode +=
						this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
				}
				// console.log(this.identifyCode); //验证码
			},
		},
	};
</script>

<style scoped>
	@import "./account.css";
</style>
